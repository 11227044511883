<script setup>
import { useCommon } from "@/store";

const localePath = useLocalePath();

const getFeature = computed(() => {
  return useCommon().getAllFeatureFlags;
});

const showSubscribePage = computed(() => {
  return getFeature.value["website-subscription-page"];
});

const range = ref(10);
const percentage = computed(() => {
  let result = 0;
  const val = range.value;
  if (val > 10 && val <= 100) result = 5;
  if (val > 100 && val <= 250) result = 4;
  if (val > 250 && val <= 500) result = 3;
  if (val > 500) result = 2.5;
  return result;
});
</script>

<template>
  <div class="what-will-it-cost">
    <h2 v-text="$t('WHATWILLITCOST')" />
    <div class="content">
      <div class="pay-as-u-sell">
        <h3 v-text="$t('PAYASYOUSELL')" />
        <p v-text="$t('CALCULATETHEFEE')" />
        <h2
          class="percentage"
          v-text="`${percentage}%`"
        />
        <span class="small-body">
          {{ $t("FEEACCORDINGTO", { value: range }) }}</span>
        <div
          class="revenue"
          v-text="$t('SELECTYOURREVENUE')"
        />
        <range-slider
          :model-value="range"
          class="mt-5 xl:mt-7"
          :min="10"
          :max="600"
          @update:model-value="range = $event"
        />
        <div
          class="range-number"
          :class="{ 'range-600': range > 520 }"
        >
          <span> 0 to $10K </span><span>$600K+</span>
        </div>
        <div class="it-is-free">
          <span class="circle" />
          <span v-text="$t('ITISFREEUPTO')" />
        </div>
      </div>
      <div>
        <div
          v-sanitize-html="$t('SCISFREETOSETUP')"
          class="body-1 font-normal text-secondary-300"
        />
        <ul class="body-1">
          <li>
            <div class="circle" />
            <div v-text="$t('PAYASYOUSELL')" />
          </li>
          <li>
            <div class="circle" />
            <div v-text="$t('FREETOSTART')" />
          </li>
        </ul>
        <div class="try-it-now">
          <nuxt-link
            :to="
              localePath({ name: showSubscribePage ? 'subscribe' : 'signup' })
            "
          >
            <sc-button
              primary
              :label="showSubscribePage ? $t('SUBSCRIBENOW') : $t('TRYITNOW')"
            />
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

h2 {
  @apply text-center text-secondary-400 font-bold;
}

.content {
  @apply mt-6 lg:mt-12 grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 items-center;
}

.pay-as-u-sell {
  @apply bg-success-100 p-6 xl:p-12;
  border-radius: rem(25px);
}

h3,
h2,
.body-2 {
  @apply text-center;
}

h3 {
  @apply font-semibold text-secondary-400;
}

.percentage {
  @apply font-bold mt-6 text-secondary-300;
}

p {
  @apply body-2 mt-4 xl:mt-2 text-secondary-300;
  @media (max-width: 1024px) {
    font-size: rem(14px) !important;
    line-height: rem(21px);
  }
}

.small-body {
  @apply block mt-2 xl:mt-1 text-center text-secondary-500;
}

.revenue {
  @apply text-lg leading-4 mt-8 text-secondary-300 font-medium;
}

.it-is-free {
  @apply text-base mt-4 xl:mt-8 leading-5 text-secondary-300 flex items-center;

  span {
    &:first-child {
      @apply w-3 h-3 bg-info-500 rounded-full;
    }
    &:last-child {
      @apply ml-2;
    }
  }
}

.range-number {
  @apply flex items-center justify-between mt-3 text-info-100;

  &.range-600 {
    @apply text-secondary-700;
  }
}

li {
  @apply mt-2 text-secondary-300 flex items-center;

  &:last-child {
    @apply mt-1 xl:mt-2;
  }

  div {
    &.circle {
      @apply w-3 h-3 bg-primary-900 rounded-full;
    }

    &:last-child {
      @apply ml-2 xl:ml-3;
    }
  }
}

.try-it-now {
  @apply hidden md:block mt-6 xl:mt-8;
}
</style>
