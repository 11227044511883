<script setup>
import { useCommon } from "@/store";

const localePath = useLocalePath();

const getFeature = computed(() => {
  return useCommon().getAllFeatureFlags;
});

const showSubscribePage = computed(() => {
  return getFeature.value["website-subscription-page"];
});
</script>

<template>
  <div class="home-header">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-8 items-center">
      <div>
        <h1 v-text="$t('ONEHEADLESSSOFTWARE')" />
        <p
          v-sanitize-html="$t('TOSUPPORTYOYRENTIRE')"
          class="mt-6 text-secondary-300 text-base leading-7 lg:text-2xl lg:leading-10"
        />
        <p
          class="mt-1 text-secondary-300 text-base leading-7 lg:text-2xl lg:leading-10"
          v-text="$t('SINGLEBACKENDTOMANAGE')"
        />
        <div>
          <nuxt-link
            :to="
              localePath({ name: showSubscribePage ? 'subscribe' : 'signup' })
            "
          >
            <sc-button
              size="large"
              primary
              class="try-it-button"
              :label="showSubscribePage ? $t('SUBSCRIBENOW') : $t('TRYITNOW')"
            />
          </nuxt-link>
        </div>
        <div class="body-2 mt-2 lg:mt-3">
          <span
            class="text-info-100"
            v-text="
              showSubscribePage
                ? $t('SUBSCRIBENOWANDGETYOURFREEMIUM')
                : $t('TRYITNOWANDGETYOURFREEMIUM')
            "
          />
        </div>
      </div>
      <div class="flex justify-center max-w-full h-auto">
        <img
          src="/images/website_home_header.png"
          :alt="$t('ONEHEADLESSSOFTWARE')"
          class="w-auto h-auto"
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

h1 {
  @apply font-bold text-secondary-400;
  @apply text-[3rem] #{!important};
  line-height: rem(56px);
  @media (min-width: 1024px) {
    font-size: rem(64px) !important;
    line-height: rem(76px);
  }
}

:deep(.bg-grad) {
  @media (min-width: 1024px) {
    background: linear-gradient(89.99deg, #e2eaff 0.01%, #ffffff 132.43%);
  }
}

.try-it-button {
  @apply mt-8 lg:mt-12 py-3;
  width: rem(249px);
  font-size: rem(24px);
  font-weight: 700;
  line-height: rem(24px);
}
</style>
