<script setup>
// import { useI18n } from "vue-i18n";

const { t } = useI18n();
const items = ref([
  {
    image: "/images/icon_settings.png",
    text: t("CREATEASHOPINSERVERALMINUTES"),
  },
  {
    image: "/images/website_home_icon_plus.png",
    text: t("INTEGRATESHOPCIRCUIT"),
  },
  {
    image: "/images/activity.png",
    text: t("STARTSELLINGYOURPRODUCT"),
  },
]);
</script>

<template>
  <div class="what-can-you-do">
    <h2
      class="text-center text-secondary-800 font-bold"
      v-text="$t('WHATCANYOUDO')"
    />
    <div
      class="mt-8 md:mt-12 grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 lg:gap-16"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="box-item"
      >
        <img :src="item.image">
        <p v-sanitize-html="item.text" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

.box-item {
  @apply p-8 bg-success-200;
  border-radius: rem(15px);
  img {
    width: rem(85px);
    height: rem(80px);
    @media (max-width: 768px) {
      @apply w-auto h-12;
    }
  }
  p {
    @apply mt-6 text-secondary-300 text-base leading-[1.5rem] md:text-xl md:leading-8;
    @media (min-width: 768px) {
      margin-top: rem(42px);
    }
  }
}
</style>
