<script setup>
import queries from "public/queries.json";

const config = useRuntimeConfig();
const hover = ref(false);
const focus = ref(false);
const loading = ref(false);
const tab = ref(0);
const customQueries = ref("");
const response = ref("");
const options = ref({
  theme: "vs-dark",
  fontSize: 16,
  fontFamily: "SF Pro Display",
  overviewRulerBorder: false,
  overviewRulerLanes: 0,
  scrollBeyondLastLine: false,
  tabSize: 4,
  autoIndent: true,
  minimap: {
    enabled: false,
  },
});

const options2 = ref({
  theme: "vs-dark",
  fontSize: 16,
  fontFamily: "SF Pro Display",
  overviewRulerBorder: false,
  overviewRulerLanes: 0,
  scrollBeyondLastLine: false,
  readOnly: true,
  minimap: {
    enabled: false,
  },
});

onMounted(() => {
  customQueries.value = queries.getproducts;
});

const graphqlURL = computed(() => {
  return config.public.graphqlUrl;
});

const tabChange = (value) => {
  if (value === 0) customQueries.value = queries.getproducts;
  if (value === 1) customQueries.value = queries.getcollections;
  if (value === 2) customQueries.value = queries.search;
  tab.value = value;
};

const submit = async () => {
  loading.value = true;
  focus.value = true;
  const { data } = await useFetch(config.graphqlUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Shop-Key": config.duplicateShopKey,
    },
    body: JSON.stringify({
      query: customQueries.value,
    }),
  });
  response.value = JSON.stringify(data.value.data, null, 4);
  loading.value = false;
};
</script>

<template>
  <div class="content">
    <div class="container">
      <h2 v-text="$t('FROMDEVELOPERFORDEVELOPER')" />

      <p
        class="subtitle"
        v-text="$t('WITHSCYOURCONTENTISACCESSIBLE')"
      />

      <div class="tabs">
        <div
          class="tab-item"
          :class="{ active: tab === 0 }"
          @click="tabChange(0)"
          v-text="$t('ALLPRODUCTS')"
        />
        <div
          class="tab-item"
          :class="{ active: tab === 1 }"
          @click="tabChange(1)"
          v-text="$t('COLLECTION')"
        />
        <div
          class="tab-item"
          :class="{ active: tab === 2 }"
          @click="tabChange(2)"
          v-text="$t('SEARCH')"
        />
      </div>

      <div class="tab-content">
        <div class="request">
          <div
            class="play-btn"
            tabindex="-1"
            @mouseenter="hover = true"
            @mouseleave="hover = false"
            @blur="focus = false"
            @click.prevent="submit"
          >
            <svg-play-focus v-if="focus" />
            <svg-play-hover v-else-if="hover" />
            <svg-play v-if="!hover && !focus" />
          </div>
          <client-only>
            <monaco-editor
              v-model="customQueries"
              class="editor"
              lang="graphql"
              :options="options"
            />
          </client-only>
        </div>
        <div
          class="response"
          :class="{ 'flex items-center justify-center': response === '' }"
        >
          <div v-if="response != ''">
            <client-only>
              <monaco-editor
                v-model="response"
                class="editor2"
                lang="json"
                :options="options2"
              />
            </client-only>
          </div>
          <p
            v-else
            class="body-1 w-2/3 text-center text-secondary-100"
            v-text="$t('PLEASETAPTHEPLAY')"
          />
        </div>
      </div>

      <div class="image-banner bloxk">
        <svg-mobile-banner-image class="float-right" />
      </div>

      <h4 v-text="$t('DOYOUWANTTOTRY')" />
      <div class="flex items-center justify-center">
        <a
          :href="graphqlURL"
          target="_blank"
        >
          <sc-button
            :primary="false"
            border
            class="bg-transparent mx-auto mt-8"
            :label="$t('RUNINYOURSELF')"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

.content {
  @apply bg-secondary-900 py-8 xl:py-12;
}

h2 {
  @apply text-center text-secondary-100 font-bold;
}

.subtitle {
  @apply xl:w-1/3 text-secondary-100 text-center mx-auto mt-4 xl:mt-6;
}

.tabs {
  @apply hidden sm:flex mt-12;
}

.tab-item {
  @apply py-5 px-6 bg-secondary-600 hover:bg-secondary-300 text-secondary-100 text-lg leading-4 font-semibold rounded-t-lg mr-1 cursor-pointer select-none;

  &.active {
    @apply bg-secondary-800;
  }
}

.tab-content {
  @apply sm:grid grid-cols-2 gap-4 relative hidden overflow-hidden;
  height: rem(632px);
}

.request {
  @apply bg-secondary-800 overflow-hidden;
  border-radius: 0 rem(15px) rem(15px) rem(15px);
}

.editor {
  @apply h-full mt-5;
  max-height: 600px;
}

.editor2 {
  @apply w-full h-full mt-5;
  min-width: 400px;
  min-height: 600px;
  height: 100%;
}

:deep(.monaco-editor) {
  @apply w-full;
  .line-numbers {
    @apply text-secondary-100 text-base;
  }
  .scroll-decoration {
    box-shadow: none;
  }
  &::-webkit-scrollbar {
    width: rem(10px);
  }
  &::-webkit-scrollbar-track {
    @apply bg-secondary-800;
    border-radius: rem(5px);
  }
  &::-webkit-scrollbar-thumb {
    background: #8e8e93;
    border-radius: rem(5px);
  }
  .margin {
    @apply bg-secondary-800;
  }
}

:deep(.monaco-editor-background) {
  @apply bg-secondary-800;
}

:deep(.margin) {
  @apply bg-secondary-800;
}

:deep(.overflow-guard) {
  &::-webkit-scrollbar {
    width: rem(10px);
  }
  &::-webkit-scrollbar-track {
    @apply bg-secondary-800;
    border-radius: rem(5px);
  }
  &::-webkit-scrollbar-thumb {
    background: #8e8e93;
    border-radius: rem(5px);
  }
}

.response {
  @apply bg-secondary-800 h-full w-full overflow-hidden;
  border-radius: rem(15px);
}

.play-btn {
  @apply absolute cursor-pointer z-50;
  top: calc(50% - 44px);
  left: calc(50% - 44px);
}

.image-banner {
  @apply relative -mr-4 sm:hidden mt-6;
  height: rem(630px);

  img {
    @apply absolute right-0;
  }
}

h4 {
  @apply text-secondary-100 font-semibold text-center mt-8 xl:mt-16;
}

:deep(.sc-btn.sc-btn--secondary) {
  @apply bg-transparent border-secondary-100 text-secondary-100 hover:text-secondary-900 hover:bg-secondary-200;
}
</style>
