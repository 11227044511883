<script setup>
// import { useI18n } from "vue-i18n";
import { useCommon } from "@/store";

const { t } = useI18n();
const localePath = useLocalePath();

const items = ref([
  {
    title: t("DEVELOPERS"),
    text: t("OPENFORNEWTECHNICAL"),
  },
  {
    title: t("WEBAGENCIES"),
    text: t("OFFERECOMMERCESOLUTIONS"),
  },
  {
    title: t("SHOPOWNERS"),
    text: t("SELLONMULTIPLECHANNELS"),
  },
]);

const getFeature = computed(() => {
  return useCommon().getAllFeatureFlags;
});

const showSubscribePage = computed(() => {
  return getFeature.value["website-subscription-page"];
});
</script>

<template>
  <div class="bg-success-100 py-12">
    <div class="container px-4 lg:px-0">
      <h2
        class="text-center text-secondary-400 font-bold"
        v-text="$t('WHOISITFOR')"
      />
      <div class="mt-8 lg:mt-12 grid grid-cols-1 lg:grid-cols-3">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="box-item"
        >
          <h4
            class="font-semibold text-secondary-400"
            v-text="item.title"
          />
          <p
            class="body-1 mt-4 lg:mt-6 text-secondary-300"
            v-text="item.text"
          />
        </div>
      </div>
      <div class="mt-6 flex items-center justify-center">
        <nuxt-link
          :to="localePath({ name: showSubscribePage ? 'subscribe' : 'signup' })"
        >
          <sc-button
            :label="showSubscribePage ? $t('SUBSCRIBENOW') : $t('TRYITNOW')"
            primary
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

.box-item {
  @apply p-6 lg:py-10 text-center;
  padding-right: rem(46px);
  padding-left: rem(46px);
  @media (max-width: 768px) {
    padding-right: rem(26px);
    padding-left: rem(26px);
  }

  &:nth-child(2) {
    @apply border-t border-b lg:border-t-0 lg:border-b-0 lg:border-r lg:border-l border-secondary-200 border-solid;
  }
}
</style>
